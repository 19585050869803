























import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import GoalSettingForm, { GoalFormData } from '@/components/organisms/GoalSettingFormEntrance.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { EntranceChoiceSchool } from '@/models/api/studentGoal'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import moment from 'moment'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    GoalSettingForm,
    ButtonBase,
  },
})
export default class GoalSettingEntrance extends Mixins(LocalMoment, LoadEnableSubjectsApi, ClassModeChangeAble) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  @Prop({ default: null })
  goalId?: number | null

  // 編集モード
  private get isEdit() {
    return this.goalId ? true : false
  }

  // TODO: パラメータ見直し
  private formData: GoalFormData = {
    comment: '',
    testDay: '',
    targetSchool: '',
  }

  /**
   * フォームのバリデーション状態
   * 全項目入力済み & 教科入力蘭に0以上が入力されているか
   * 定期テストの場合は各教科の目標点数も含む
   */
  private get isSaveAble(): boolean {
    return this.formData.comment.length > 0 && this.formData.testDay.length > 0 && this.formData.targetSchool.length > 0
  }

  private get colorTypeSaveBtn(): string {
    return this.isSaveAble ? 'gradation-orange' : 'pointer-events-none'
  }

  private onEditForm(newVal: GoalFormData) {
    this.formData = newVal
  }

  private onClickSave(): void {
    if (!this.isSaveAble) return

    this.saveStudentGoal()
  }
  private onClickClear(): void {
    this.clearFormData()
  }

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    if (this.isEdit) await this.loadCurrentGoal()
    Vue.prototype.$loading.complete()
  }

  /**
   * 編集対象の目標情報取得
   */
  private async loadCurrentGoal() {
    if (this.goalId == null) return

    // 編集対象の目標情報取得
    const { data }: { data: EntranceChoiceSchool } = await Vue.prototype.$http.httpWithToken.get(
      `/student_goals/${this.goalId}?classModeCode=NY`
    )

    this.formData.comment = data.comment
    this.formData.testDay = moment(data.testDay).format('YYYY/MM/DD')
    this.formData.targetSchool = data.choiceSchoolName
  }

  /**
   * フォームデータクリア
   */
  private clearFormData() {
    this.formData.comment = ''
    this.formData.testDay = ''
    this.formData.targetSchool = ''
  }

  /**
   * 目標作成リクエスト
   */
  private async saveStudentGoal() {
    Vue.prototype.$loading.start()
    const params = Object.assign(this.formData, { classModeCode: 'NY' })
    if (this.isEdit) {
      // 更新
      await Vue.prototype.$http.httpWithToken
        .patch(`/student_goals/${this.goalId}`, params)
        .then(() => {
          alert('目標を更新しました。')
        })
        .catch((error: any) => {
          if (error?.response?.data?.status === 422) alert('目標は、当日中でなければ変更できません。')
        })
        .finally(() => {
          Vue.prototype.$loading.complete()
          this.$router.push('/student/goal-list')
        })
    } else {
      // 新規作成
      await Vue.prototype.$http.httpWithToken
        .post('/student_goals', params)
        .then(() => {
          alert('新規目標を作成しました。')
        })
        .catch((error: any) => {
          if (error?.response?.data?.status === 409) alert('既に作成済みの目標が存在します。')
        })
        .finally(() => {
          Vue.prototype.$loading.complete()
          this.$router.push('/student/goal-list')
        })
    }
  }
}
