



































import { Component, Prop, Mixins } from 'vue-property-decorator'
import SelectBase from '@/components/atoms/SelectBase.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import LocalMoment from '../atoms/LocalMoment.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'

export type GoalFormData = {
  comment: string // コメント
  testDay: string // 入試日
  targetSchool: string // 志望校
}

@Component({
  components: {
    SelectBase,
    DatePickerIcon,
    RadioGroup,
  },
})
export default class GoalSettingFormEntrance extends Mixins(LocalMoment, ClassModeMethods) {
  @Prop()
  formData!: GoalFormData

  private get innerFormData(): GoalFormData {
    return this.formData
  }

  private set innerFormData(newVal) {
    this.$emit('edit-form', newVal)
  }

  private onSelectTestDay(newVal: string) {
    this.innerFormData.testDay = newVal
  }
}
