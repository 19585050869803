













import { Component, Prop, Vue } from 'vue-property-decorator'
import RadioBase from '@/components/atoms/RadioBase.vue'

@Component({
  components: {
    RadioBase,
  },
  inheritAttrs: false,
})
export default class RadioGroup extends Vue {
  @Prop()
  name!: string

  @Prop()
  items!: { label: string; value: string | number }[]

  @Prop()
  checked!: string | number

  private onChange(value: string | number): void {
    this.$emit('input', value)
  }
}
